<script lang="ts" setup>
interface Props {
  urlPrefix?: string
  preselected?: string
  disabled?: boolean
  placeholder?: string
  collapse?: boolean
}

const props = defineProps<Props>()
const { t } = useI18n()
const id = useId()
const formId = useId()

const { formData, persistInUrl, setData, urlValues } = useFiltersForm({
  urlPrefix: props.urlPrefix,
  filterName: URL_FILTER.SEARCH,
  resetFilters: [URL_FILTER.PAGE],
})

function handleInput(e: string) {
  persistInUrl({ [URL_FILTER.SEARCH]: e })
}

if (urlValues.value?.[0] || props.preselected) {
  setData(urlValues.value?.[0] ?? props.preselected)
}

watch(urlValues, () => {
  setData(urlValues.value[0])
})

defineOptions({
  name: 'FiltersSearch',
})
</script>

<template>
  <div>
    <FormKit
      :id="formId"
      v-model="formData"
      :name="formId"
      type="form"
      :actions="false"
      @submit="persistInUrl"
    >
      <FormKit
        :id
        type="search"
        :name="URL_FILTER.SEARCH"
        autocomplete="off"
        :delay="200"
        prefix-icon="search"
        suffix-icon="close"
        :placeholder="placeholder ?? t('placeholder.search')"
        :collapse="collapse ?? true"
        :classes="{
          inner: '!rounded-full',
          input: '!rounded-full',
        }"
        @input="handleInput"
      />
    </FormKit>
  </div>
</template>

<i18n>
de:
  placeholder:
    search: Suche
es:
  placeholder:
    search: Buscar
</i18n>
